import './App.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Header from './components/header/header.component';
import Main from './components/main/main.component';
import Footer from './components/footer/footer.component';
import Contacto from './components/contacto/contacto.component';

library.add(fas);

function App() {
  return (
    <div className="App">
      <Header />
      <div className='App__main-content'>
        <Main />
      </div>
      <Contacto />
      <Footer />
    </div>
  );
}

export default App;
