import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../home/home.component';
import QuiSom from '../qui-som/qui-som.component';
import Serveis from '../serveis/serveis.component';
import MediacioLectora from '../mediacio-lectora/mediacio-lectora.component';
import PrescripcioLectora from '../prescripcio-lectora/prescripcio-lectora.component';

const Main = () => {
  return (
    <Routes>
      <Route exact path='/' element={<Home />}></Route>
      <Route exact path='/qui-som' element={<QuiSom />}></Route>
      <Route exact path='/serveis' element={<Serveis />}></Route>
      <Route exact path='/serveis/mediacio-lectora' element={<MediacioLectora />}></Route>
      <Route exact path='/serveis/prescripcio-lectora' element={<PrescripcioLectora />}></Route>
      <Route exact path='/contacta' element={<div className='contacta-page'> </div>}></Route>


    </Routes>
  );
}

export default Main;