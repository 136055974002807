import './slider.component.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

function Slider() {

  return (
      <Carousel >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require('../../assets/img/slider/slider_1.jpg')}
              alt="slide"
            />
            <div className='carousel-caption slider__box'>
              <h3 className='slider__title'>“El nostre objectiu principal serà la creació de vincle amb els clients a partir d’una nova forma de comunicació que sigui, assertiva, propera i interactiva.”</h3>
            </div>
          </Carousel.Item>
          <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('../../assets/img/slider/slider_2.jpg')}
            alt="slide"
          />
          <div className='carousel-caption slider__box'>
            <h3 className='slider__title'>Nou club de lectura infantil</h3>
            <p className='slider__text'>
            Ara per tan sols 25€/mes i comparteix lectures i pensaments amb tots nosaltres.
            </p>
            <Link to="/contacta" class="slider__button">
              Inscriu-te
            </Link>
          </div>
          </Carousel.Item>
      </Carousel>
    
  );
}

export default Slider;