import React from 'react';
import './serveis.component.scss';
import { Link } from 'react-router-dom';


const Serveis = () => {
  return (
    <div className="serveis section" id='serveis'>
      <h2 className="serveis__title">
        Serveis
      </h2>
      <div className="serveis__list">
        <Link to="/serveis/mediacio-lectora" class="serveis__list-item-link">
          <img className='quote__icon' src={require("../../assets/img/mediacion-lectora_icono.png")} alt={"Mediació lectora"} width="30%" />
          <h4 className="serveis__list-item-title">
            Mediació Lectora
          </h4>
        </Link>
        <Link to="/serveis/prescripcio-lectora" class="serveis__list-item-link">
          <img className='quote__icon' src={require("../../assets/img/prescripcion-lectora_icono.png")} alt={"Prescripció Lectora"} width="30%" />
          <h4 className="serveis__list-item-title">
            Prescripció Lectora
          </h4>
        </Link>
      </div>
    </div> 
  );
}

export default Serveis;