import './footer.component.scss';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer">
      <div className='footer__nav'>
        <h5 className='footer__info-text'>Enllaços ràpids:</h5>
        <div className='footer__nav-list'>
          <Link to="/" class="footer__link">
            Inici
          </Link>
          <Link to="/qui-som" class="footer__link">
            Qui som
          </Link>
          <Link to="/serveis" class="footer__link">
            Serveis
          </Link>
          <Link to="/contacta" class="footer__link">
            Contacta
          </Link>
        </div>
      </div>
      <div className="footer__info">
        <p className="footer__info-text">
          Un programa per al foment de la lectura, per Paola Sanz.
        </p>
        <div className="footer__info-detail">
          <Link to="https://www.instagram.com/escoltaliteraria" class="footer__link">
            <img className='footer__icon' src={require("../../assets/img/instagram_icono.png")} alt={"Instagram de Escolta Literaria"} width="30%" />
          </Link>
          <span>@escolta literaria</span>
        </div>
        <div className="footer__info-detail">
          <img className='footer__icon' src={require("../../assets/img/email_icono.png")} alt={"Email de Escolta Literaria"} width="30%" />
          <span>escoltaliteraria@gmail.com</span>
        </div>
        <div className="footer__info-detail">
        <img className='footer__icon' src={require("../../assets/img/telefono_icono.png")} alt={"Telefono de Escolta Literaria"} width="30%" />
          <span>+34 6586238xx</span>
        </div>
      </div>
      </div>
  );
}

export default Footer;
