import React from 'react';
import './qui-som.component.scss';


const QuiSom = () => {
  return (
        <div className="qui-som section">
            <h2 className="qui-som__title playfair-bold-font">Qui som</h2>
            <div className='qui-som__content'>
              <div className='qui-som__subsection'>
                <div className='qui-som__subsection-text'>
                  <h3 className='qui-som__subsection-title playfair-bold-font'>
                    Els nostres orígens
                  </h3>
                  <p>
                    Escolta literària neix en el context de la pandèmia. Un moment crític on el consum de productes digitals augmenta i  la lectura es consolida com un refugi.
                  </p>
                  <p>
                    Una oportunitat per posar en marxa el nostre projecte i doncs, cobrir les necessitats d’entreteniment de la ciutadania. 
                  </p>
                </div>
                <div className='qui-som__image-wrapper'>
                  <img className='qui-som__image' src={require("../../assets/img/foto_slogan.jpg")} alt={"Escolta Literària"} width="100%"/> 
                </div>
              </div>
              <div className='qui-som__subsection'>
                <div className='qui-som__image-wrapper'>
                  <img className='qui-som__image' src={require("../../assets/img/libreria pequeña.jpg")} alt={"Escolta Literària"} width="100%"/> 
                </div>
                <div className='qui-som__subsection-text'>
                  <h3 className='qui-som__subsection-title playfair-bold-font'>
                    Els nostres objectius
                  </h3>
                  <ul className='qui-som__subsection-list'>
                    <li>
                      Fomentar descoberta de noves propostes literàries. 
                    </li>
                    <li>
                      Estimular l’hàbit lector en tots els cicles de la vida. 
                    </li>
                    <li>
                      Desenvolupar una comunitat lectora cohesionada a partir del diàleg. 
                    </li>
                    <li>
                      Potenciar les capacitats i habilitats comunicatives dels lectors.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='qui-som__subsection'>
                <div className='qui-som__subsection-text'>
                  <h3 className='qui-som__subsection-title playfair-bold-font'>
                    Els nostres valors
                  </h3>
                  <ul className='qui-som__subsection-list'>
                    <li>
                      Incentivar el gust per la lectura. 
                    </li>
                    <li>
                      Fomentar la creativitat. 
                    </li>
                    <li>
                      Crear un espai de confiança i diàleg entre la comunitat lectora.
                    </li>
                  </ul>
                </div>
                <div className='qui-som__image-wrapper'>
                  <img className='qui-som__image' src={require("../../assets/img/JOVEN LECTORA.jpg")} alt={"Escolta Literària"} width="100%"/> 
                </div>
              </div>
            </div>
        </div>
  );
}

export default QuiSom;