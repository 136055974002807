import React from 'react';
import './contacto.component.scss';
import FormContacto from '../forms/form-contacto.component';


const Contacto = () => {
  return (
    <div className="contacto">
      <h3 className="contacto__title">
        Contacta amb nosaltres
      </h3>
      <div className='contacto__form'>
        <FormContacto></FormContacto>
      </div>
    </div>
  );
}

export default Contacto;