import React, { useRef, useState  } from "react";
import emailjs from "@emailjs/browser";
import './form.component.scss';


//service id contacto:service_uzn5i36
// public key: ulGmlUhJu9azPOiBh 
//template id: template_l3chvyk

const FormContacto = (props) => {
    const form = useRef();
    const [messageSent, setMessageSent] = useState(false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

    const checkHandler = () => {
      setPrivacyPolicyAccepted(!privacyPolicyAccepted);
    }

    const sendEmail = (e) => {
      e.preventDefault();
      setMessageSent(true);
      emailjs
        .sendForm(
          "service_uzn5i36",
          "template_l3chvyk",
          form.current,
          "ulGmlUhJu9azPOiBh"
        )
        .then(
          (result) => {
            console.log("sent");
          },
          (error) => {
            console.log(error.text);
          }
        );
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="form">
          <div className="form__input-wrapper">
            <label className="form__label">
              Nom
            </label>
            <input  className="form__input" type="text" name="nom" id="nom"/>
          </div>
          <div className="form__input-wrapper">
            <label className="form__label">
              Telèfon
            </label>
            <input  className="form__input" type="text" name="telefon" id="telefon"/>
          </div>
          <div className="form__input-wrapper">
            <label className="form__label">
              Email
            </label>
            <input  className="form__input" type="text" name="email" id="email"/>
          </div>
          <div className="form__input-wrapper">
            <label className="form__label">
              Tipus de servei a contractar
            </label>
            <select id="servei" name="servei" className="form__input">
              <option value="prescripció lectora">Prescripció Lectora</option>
              <option value="mediació lectora">Mediació Lectora</option>
            </select>
          </div>
          <div className="form__input-wrapper">
            <label className="form__label">
              Missatge
            </label>
            <textarea className="form__input form__input--textarea" name="missatge" id="missatge" rows="4"></textarea>
          </div>

          <div className="form__input-wrapper-checkbox">
            <input  className="form__input-checkbox" type="checkbox" name="privacy_checkbox" id="privacy_checkbox" required onChange={checkHandler}/>
            <label className="form__label">
            Escolta literària us informa sobre la Política de privacitat, respecte al tractament i protecció de dades de caràcter personal d’usuaris i clients que puguin ser recollits durant la navegació o contractació dels nostres serveis. 
            L’ús d’aquest formulari significa que doneu consentiment al tractament de les vostres dades personals seguint la normativa vigent contemplada en la Llei Orgànica 3/2018, del 5 de desembre, de Protecció de Dades personals i de Garantia de Drets Digitals (LOPD GDD). 

            </label>
          </div>
          {!privacyPolicyAccepted ? <p className="form__warning">Accepta la politica de privacitat per enviar, si us plau</p> : <></>
          }
          {!messageSent ?
            <button type="submit" value="Enviar" className={"boton boton--"+ privacyPolicyAccepted} disabled={!privacyPolicyAccepted}>Enviar</button>
            : <p className={'form__message-sent form__message-sent--' + messageSent}>
                Moltes gràcies per el teu missatge!
              </p>
          }
        </form>
    );
  };
  
  export default FormContacto;

        