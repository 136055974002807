import './header.component.scss';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='header-component'>
      <div className='topbar'>
        <a href="https://www.instagram.com/escoltaliteraria" className='footer__link'>
          <img className='topbar__icon' src={require("../../assets/img/instagram_icono.png")} alt={"Instagram de Escolta Literaria"} />
        </a>
      </div>
      <div className="header">
        <Link to="/" class="logo">
          <img className="header__img" src={require("../../assets/img/logotipo.png")}  alt={"Paola&Albert"}/>
        </Link>


        <button onClick={() => setIsOpen(!isOpen)} className={'hamburger-menu__button hamburger-menu__button-is-open--' + isOpen}>
          <div className="stick stick1" />
          <div className="stick stick2" />
          <div className="stick stick3" />
        </button>

        {/* We'll conditionally render our links based on isOpen */}
        {isOpen && (
          <nav className='hamburger-menu'>
            <Link to="/" class="nav__list-item-link">
                Inici
            </Link>
            <Link to="/qui-som" class="nav__list-item-link">
              Qui som
            </Link>
            <Link to="/serveis" class="nav__list-item-link">
              Serveis
            </Link>
            <Link to="/contacta" class="nav__list-item-link">
              Contacta
            </Link>
          </nav>
        )}
        <nav className="nav">
          <ul className="nav__list">
            <li className="nav__list-item">
              <Link to="/" className="nav__list-item-link">
                Inici
              </Link>
            </li>
            <li className="nav__list-item">
              <Link to="/qui-som" className="nav__list-item-link">
                Qui som
              </Link>
            </li>
            <li className="nav__list-item">
              <Link to="/serveis" className="nav__list-item-link">
                Serveis
              </Link>
            </li>
            <li className="nav__list-item">
              <Link to="/contacta" className="nav__list-item-link">
                Contacta
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
