import React from 'react';
import './prescripcio-lectora.component.scss';


const PrescripcioLectora = () => {
  return (
    <>
    <img className='prescripcio-lectora__banner' src={require("../../assets/img/libreria pequeña.jpg")} alt={"Prescripció Lectora per a famílies"} width="100%" />
    <div className='prescripcio-lectora'>
      <h2 className="prescripcio-lectora__title">
          Prescripció Lectora
      </h2>
      <div className="prescripcio-lectora__list">
          <div class="prescripcio-lectora__list-item">
            <img className='quote__icon' src={require("../../assets/img/prescripcio-lectora_families-icon.png")} alt={"Prescripció Lectora per a famílies"} width="30%" />
            <h4 className="prescripcio-lectora__list-item-title">
              Famílies
            </h4>
            <p className='prescripcio-lectora__list-item-subtitle'>
              Ens apropem a la lectura i assolim el gust per la lectura.
            </p>
            <p className='prescripcio-lectora__list-item-text'>
              Aconsegueix comunicar i compartir les teves emocions i inquietuds amb els altres.
            </p>
          </div>
          <div class="prescripcio-lectora__list-item">
            <img className='quote__icon' src={require("../../assets/img/prescripcio-lectora_joves-icon.png")} alt={"Prescripció Lectora per a joves"} width="30%" />
            <h4 className="prescripcio-lectora__list-item-title">
              Joves
            </h4>
            <p className='prescripcio-lectora__list-item-subtitle'>
              Ens endinsem en la lectura.
            </p>
            <p className='prescripcio-lectora__list-item-text'>
              Adquireix esperit crític i una actitud positiva i resolutiva davant el món que ens envolta.
            </p>
          </div>
      </div>
    </div>
    </>
  );
}

export default PrescripcioLectora;