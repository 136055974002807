import React from 'react';
import './mediacio-lectora.component.scss';


const MediacioLectora = () => {
  return (
    <>
    <img className='mediacio-lectora__banner' src={require("../../assets/img/Libreria_mediació lectora.jpg")} alt={"Prescripció Lectora per a famílies"} width="100%" />
    <div className='mediacio-lectora'>
      <h2 className="mediacio-lectora__title">
          Mediació Lectora
      </h2>
      <div className="mediacio-lectora__list">
          <div class="mediacio-lectora__list-item">
            <img className='mediacio-lectora__image' src={require("../../assets/img/club-lectura_icon.png")} alt={"Prescripció Lectora per a famílies"} width="30%" />
            <div className='mediacio-lectora__list-item-content'>
              <h4 className="mediacio-lectora__list-item-title">
                Clubs de lectura
              </h4>
              <ul className="mediacio-lectora__club-list">
                <li className="mediacio-lectora__club-list-item">
                  Presencials
                </li>
                <li className="mediacio-lectora__club-list-item">
                  Virtuals
                </li>
                <li className="mediacio-lectora__club-list-item">
                  Temàtics
                </li>
              </ul>
            </div>
          </div>
          <div class="mediacio-lectora__list-item">
            <img className='mediacio-lectora__image' src={require("../../assets/img/mediacio-lectora_cicles-tematics-icon.png")} alt={"Prescripció Lectora per a joves"} width="30%" />
            <div className='mediacio-lectora__list-item-content'>
              <h4 className="mediacio-lectora__list-item-title">
                Cicles temàtics
              </h4>
              <p className='mediacio-lectora__list-item-text'>
                Activitats formatives orientades a l’aproximació d’un tema d’interès i d’actualitat a partir de l’estudi de la literatura.
              </p>
              <p className='mediacio-lectora__list-item-subtitle'>
                Cicle temàtic sobre el medi ambient 
              </p>
            </div>
          </div>
          <div class="mediacio-lectora__list-item">
            <img className='mediacio-lectora__image' src={require("../../assets/img/mediacion-lectora_icono.png")} alt={"Prescripció Lectora per a joves"} width="30%" />
            <div className='mediacio-lectora__list-item-content'>
              <h4 className="mediacio-lectora__list-item-title">
                Monogràfics literàris
              </h4>
              <p className='mediacio-lectora__list-item-text'>
                Sessions centrades en la trajectòria literària i artística d’un autor o il·lustrador que ha deixat la seva emprenta en el món literari.
              </p>
              <span className='mediacio-lectora__list-item-subtitle'>
                Monogràfic sobre Jane Austen 
              </span>
              <span className='mediacio-lectora__list-item-subtitle'>
                Monogràfic sobre Jimmy Liao 
              </span> 
            </div>
          </div>
          <div class="mediacio-lectora__list-item">
            <img className='mediacio-lectora__image' src={require("../../assets/img/mediacio-lectora_itineraris-literàris-icon.png")} alt={"Prescripció Lectora per a joves"} width="30%" />
            <div className='mediacio-lectora__list-item-content'>
              <h4 className="mediacio-lectora__list-item-title">
                Rutes literàries
              </h4>
              <p className='mediacio-lectora__list-item-text'>
                Itineraris literaris que posen en valor l’entorn i la literatura.
              </p>
            </div>
          </div>
      </div>
    </div>
    </>
  );
}

export default MediacioLectora;