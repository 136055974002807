
import './home.component.scss';
import Slider from '../slider/slider.component';
import { Fade } from "react-awesome-reveal";
import { Link } from 'react-router-dom';


function Home(props) {

  return (
    <div>
      <div>
        <Slider></Slider>
      </div>
      <div className="valors" id='valors'>
        <Fade>
          <h2 className="valors__title playfair-bold-font">Qui som</h2>
          <div className='valors__content'>
            <div className='valors__subsection'>
              <div className='valors__subsection-text'>
                <h3 className='valors__subsection-title playfair-bold-font'>
                  Els nostres orígens
                </h3>
                <p>
                  Escolta literària neix en el context de la pandèmia. Un moment crític on el consum de productes digitals augmenta i  la lectura es consolida com un refugi.
                </p>
                <p>
                  Una oportunitat per posar en marxa el nostre projecte i doncs, cobrir les necessitats d’entreteniment de la ciutadania. 
                </p>
              </div>
              <div className='valors__image-wrapper'>
                <img className='valors__image' src={require("../../assets/img/foto_slogan.jpg")} alt={"Escolta Literària"} width="100%"/> 
              </div>
            </div>
            <div className='valors__subsection'>
              <div className='valors__image-wrapper'>
                <img className='valors__image' src={require("../../assets/img/libreria pequeña.jpg")} alt={"Escolta Literària"} width="100%"/> 
              </div>
              <div className='valors__subsection-text'>
                <h3 className='valors__subsection-title playfair-bold-font'>
                  Els nostres objectius
                </h3>
                <ul className='valors__subsection-list'>
                  <li>
                    Fomentar descoberta de noves propostes literàries. 
                  </li>
                  <li>
                    Estimular l’hàbit lector en tots els cicles de la vida. 
                  </li>
                  <li>
                    Desenvolupar una comunitat lectora cohesionada a partir del diàleg. 
                  </li>
                  <li>
                    Potenciar les capacitats i habilitats comunicatives dels lectors.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <div className="serveis" id='serveis'>
        <Fade>
        <h2 className="serveis__title">
          Serveis
        </h2>
        <div className="serveis__list">
            <Link to="/serveis/mediacio-lectora" class="serveis__list-item-link">
              <img className='quote__icon' src={require("../../assets/img/mediacion-lectora_icono.png")} alt={"Mediació lectora"} width="30%" />
              <h4 className="serveis__list-item-title">
                Mediació Lectora
              </h4>
            </Link>
            <Link to="/serveis/prescripcio-lectora" class="serveis__list-item-link">
              <img className='quote__icon' src={require("../../assets/img/prescripcion-lectora_icono.png")} alt={"Prescripció Lectora"} width="30%" />
              <h4 className="serveis__list-item-title">
                Prescripció Lectora
              </h4>
            </Link>
        </div>
        </Fade>
      </div>
    </div>
  );
};

export default Home;
